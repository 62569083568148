//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import DATAMASTER from "../../library/dataMaster";
import UMUM from "../../library/umum";


export default {
  data() {
    return {
      

      form : {
        id : '',
        pk_unit_sasaran_indikator : '',
        deskripsi : '',
        definisi : '',
        formula : '',
        tujuan : '',
        satuan_ukur : '',
        master_jenis_indikator : '',
        penanggung_jawab : '',
        penyedia_data : '',
        sumber_data : '',
        master_periode_pelaporan : '',
        penanggung_jawab_cq : '',
      },

      text : {
        sasaran_uraian : '',
        indikator_uraian : '',
      },

      

      list_data : [],

      page_first: 1,
      page_last: 0,
      page_limit : 10,
      cari_value: "",
      cek_load_data : false,


      mdl_add: false,
      mdl_edit: false,
      mdl_hapus : false,
     

      marginFilter : '',

      UMUM : UMUM,
      DATAMASTER : DATAMASTER,
    }
  },
  methods: {


    getData : function(){
      this.cek_load_data = true;
      fetch(this.$store.state.url.URL_kinerja_manual_IK_JPT + "view", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
              tahun: this.form.tahun,
              kelas : 1,
              unit_kerja : this.unit_kerja

          })
      })
          .then(res => res.json())
          .then(res_data => {
              this.cek_load_data = false;
              this.list_data = res_data
              // console.log(res_data);
      });
    },


    addData : function() {

      fetch(this.$store.state.url.URL_kinerja_manual_IK_JPT + "addData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify(this.form)
      }).then(res_data => {
          this.Notify('Sukses Menambah Data', 'primary', 'check_circle_outline');
          this.getData();
      });
    },



    selectData : function(data){


    },


    selectAddData : function(data1, data2){
      // console.log(data1)
      // console.log(data2)

      this.text = {
        sasaran_uraian : data1.uraian,
        indikator_uraian : data2.indikator_uraian,
      };




      this.form.id = data2.id;

      this.form.pk_unit_sasaran_indikator = data2.indikator_id;

      this.form.deskripsi = data2.manual_ik_deskripsi;
      this.form.definisi = data2.manual_ik_definisi;
      this.form.formula = data2.manual_ik_formula;
      this.form.tujuan = data2.manual_ik_tujuan;
      this.form.satuan_ukur = data2.manual_ik_satuan_ukur;
      this.form.master_jenis_indikator = data2.manual_ik_master_jenis_indikator;
      this.form.penanggung_jawab = data2.manual_ik_penanggung_jawab;
      this.form.penyedia_data = data2.manual_ik_penyedia_data;
      this.form.sumber_data = data2.manual_ik_sumber_data;
      this.form.master_periode_pelaporan = data2.manual_ik_master_periode_pelaporan;
      this.form.penanggung_jawab_cq = data2.manual_ik_penanggung_jawab_cq;
    },



    getAsync : async function(){
      await DATAMASTER.getJenisIndikator();
      await DATAMASTER.getJenisSasaranKU();
      await DATAMASTER.getPeriodePelaporan();
      this.getData();
    },





    // ====================================== PAGINATE ====================================
        Notify : function(message, positive, icon){
          this.$q.notify({
            message: message,
            color: positive,
            icon: icon,
            position : 'top',
            timeout: 500,
          })
        },


        
        cari_data : function(){
            this.page_first = 1;
            this.getData();
        },


    // ====================================== PAGINATE ====================================







  },

  mounted () {

    // this.$store.commit("listJeniskategorilokasi");
    // this.$store.commit("getStorage");

    if(this.$q.platform.is.mobile){
      this.marginFilter='q-x-sm'
    }
    else{
      this.marginFilter=''
    }

    const d = new Date();
    this.form.tahun = d.getFullYear();



    var get_profile = JSON.parse(localStorage.profile);
    this.unit_kerja = get_profile.profile.unit_kerja;
    this.getAsync();

  },
}
